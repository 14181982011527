import linkedInWhite from "../../assets/images/linkedinWhite.png";
import linkedInBlack from "../../assets/svgs/linkedinBlack.svg";

import envelopeWhite from "../../assets/images/envelopeWhite.png";
import envelopeBlack from "../../assets/svgs/envelopeBlack.svg";

import githubWhite from "../../assets/images/githubWhite.png";
import githubBlack from "../../assets/svgs/github.svg";

export const lightIcons = [
  {
    name: envelopeWhite,
    url: "mailto:omarzumayajr@gmail.com",
    caption: "Email me! - omarzumayajr@gmail.com",
  },
  {
    name: linkedInWhite,
    url: "https://www.linkedin.com/in/omarzumaya",
    caption: "Connect with me on LinkedIn! - www.linkedin.com/in/omarzumaya",
  },
  {
    name: githubWhite,
    url: "https://www.github.com/Omar4589",
    caption: "Check out my Github! - Omar4589",
  },
];

export const darkIcons = [
  {
    name: envelopeBlack,
    url: "mailto:omar4589@hotmail.com",
    caption: "omarzumayajr@gmail.com",
  },
  {
    name: linkedInBlack,
    url: "https://www.linkedin.com/in/omarzumaya",
    caption: "www.linkedin.com/in/omarzumaya",
  },
  {
    name: githubBlack,
    url: "https://www.github.com/Omar4589",
    caption: "Omar4589",
  },
];
