import React from "react";

const Name = () => {
  return (
    <div
      id="name-container"
      // style={{ position: "relative", zIndex: 1 }}
      className="w-4/5 flex flex-col md:flex-row sm:items-start md:justify-center  space-x-0 relative z-10 font-soisBold select-none xl:space-x-8 md:space-x-4 text-6xl xl:text-7xl"
    >
      <p className="">OMAR</p>
      <p className="">ZUMAYA</p>
    </div>
  );
};

export default Name;
