// themes.js
export const lightTheme = {
  name: "light",
  background: "slate-100",
  text: "zinc-950",
  menuText: "#f1f5f9",
  isChecked: "#f1f5f9",
  isNotChecked: "#09090b",
  metaTag: "#f1f5f9"
};

export const darkTheme = {
  name: "dark",
  background: "zinc-950",
  text: "slate-100",
  menuText: "#09090b",
  isChecked: "#f1f5f9",
  isNotChecked: "#f1f5f9",
  metaTag: "#09090b"
};
