import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider } from './utils/ThemeContext/ThemeContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <ThemeProvider>
    <App />
  </ThemeProvider>
);